import React from "react"
import { FormControl, FormLabel, FormErrorMessage, Input, Button, Stack, Box, Text } from "@chakra-ui/react"
import { useCustomerRecover } from "@app/hooks/useCustomer"
import { useConfigContext } from "@app/providers/config"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import { graphql, useStaticQuery } from "gatsby"

const AccountForgotForm: React.FC = () => {
  const { handleChange, handleSubmit, data, loading, errors, success } = useCustomerRecover()
  const { urlResolver } = useRoutes()
  const {
    settings: { routes },
  } = useConfigContext()

  const { additionalContent } = useStaticQuery<GatsbyTypes.StaticForgotPageAdditionalContentQuery>(graphql`
    query StaticForgotPageAdditionalContent {
      additionalContent: sanityPageAccountForgot {
        additionalSubmitButton
      }
    }
  `)

  const { additionalSubmitButton } = additionalContent || {}

  return (
    <Box as="form" onSubmit={handleSubmit} mb={8}>
      <Stack spacing={5}>
        <FormControl id="email" value={data?.email} onChange={handleChange} isRequired>
          <FormLabel>Email</FormLabel>
          <Input name="email" type="email" />
        </FormControl>

        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {additionalSubmitButton}
        </Button>

        <Button variant="outline" as={Link} to={urlResolver({ title: "Login" }, routes.LOGIN).url}>
          Cancel
        </Button>

        {success && <Text>We have sent you an email</Text>}

        {errors?.length > 0 && (
          <FormControl id="error" isInvalid>
            {errors?.map(error => (
              <FormErrorMessage key={error.toString()}>
                Email address not associated with an account. Please try a different email address
              </FormErrorMessage>
            ))}
          </FormControl>
        )}
      </Stack>
    </Box>
  )
}

export default React.memo(AccountForgotForm)
